import React from "react";
import ReactDOM from "react-dom/client";
import ComponentSelector from "./ComponentSelector";
import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./routes";

const generateClassName = createGenerateClassName({
  seed: "xyz",
  productionPrefix: "abc-",
});

const root = ReactDOM.createRoot(document.getElementById("solveoapps"));
root.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <AppRoutes />
      <ComponentSelector />
      <ToastContainer />
    </StylesProvider>
  </React.StrictMode>
);
