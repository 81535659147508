// UI & Components
import { TextFieldControlled } from "../../../components/TextFieldControlled/TextFieldControlled";
import { PhoneInputControlled } from "../../../components/PhoneInputControlled/PhoneInputControlled";
import { EmailFieldControlled } from "../../../components/EmailFieldControlled/EmailFieldControlled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export const ContactInformationForm = ({ form, currentTab }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = form;

  const handleValidateStep = () => {
    currentTab(1);
  };
  const handleFormError = () => {};

  return (
    <form onSubmit={handleSubmit(handleValidateStep, handleFormError)}>
      <Grid container spacing={3}>
        <Grid container item alignItems="flex-start">
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              <TextFieldControlled
                control={control}
                name="first_name"
                label="Prénom"
                type="text"
                errors={errors.first_name}
                rules={{ required: "Le prénom est obligatoire" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControlled
                control={control}
                name="last_name"
                label="Nom"
                type="text"
                errors={errors.last_name}
                rules={{ required: "Le nom est obligatoire" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControlled
                control={control}
                name="company_name"
                label="Nom de votre société"
                type="text"
                errors={errors.company_name}
                rules={{ required: "Le nom de société est obligatoire" }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextFieldControlled
                control={control}
                name="address"
                label="Adresse"
                type="text"
                errors={errors.address}
                rules={{ required: "L'adresse est obligatoire" }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldControlled
                control={control}
                name="npa"
                label="Code Postal"
                type="text"
                errors={errors.npa}
                rules={{ required: "Le code postal est obligatoire" }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControlled
                control={control}
                name="city"
                label="Ville"
                type="text"
                errors={errors.city}
                rules={{ required: "La ville est obligatoire" }}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInputControlled
                control={control}
                name="phone"
                label="Numéro de téléphone"
                type="text"
                favorite_country={["CH", "FR", "BE"]}
                default_country="CH"
                errors={errors.phone}
                rules={{ required: "Le numéro de téléphone est obligatoire" }}
              />
            </Grid>
            <Grid item xs={12}>
              <EmailFieldControlled
                control={control}
                name="mail"
                label="Votre adresse mail"
                type="text"
                errors={errors.mail}
                rules={{ required: "L'adresse mail est obligatoire" }}
              />
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={3}>
              <Grid>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  // disabled={!isValid}
                >
                  Continuer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
