// UI & Componenets
import { TextFieldControlled } from "../TextFieldControlled/TextFieldControlled";

export const EmailFieldControlled = ({
  rules,
  control,
  name,
  label,
  errors,
}) => {
  if (!rules) rules = {};
  rules["validate"] = (value) =>
    validateEmail(value) || value === ""
      ? true
      : "Le format n'est pas correcte - Exemple: monemail@gmail.com";

  const validateEmail = (email) => {
    if (email === "" || email === null || email === undefined) {
      return "ok";
    } else {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    }
  };

  return (
    <TextFieldControlled
      control={control}
      name={name}
      label={label}
      type="text"
      errors={errors}
      rules={rules}
    />
  );
};

/*
Example:
<EmailFieldControlled control={control} name="email" label="Email" 
    errors={errors.email} rules={{ required: "L'email est obligatoire", }} />
*/
