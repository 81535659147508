// UI & Components
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Controller } from "react-hook-form";

export const RadioButtonControlled = ({
  label,
  control,
  name,
  row,
  options,
}) => {
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue=""
        render={({ field }) => (
          <RadioGroup {...field} row={row}>
            {options.map((item) => (
              <FormControlLabel
                value={item.label}
                control={<Radio />}
                label={item.label}
                key={item.key}
              />
            ))}
          </RadioGroup>
        )}
      />
    </>
  );
};

/*

Example:

<RadioGroupControlled control={control} options={languageList} name="language" label="Langue parlée" row />

*/
