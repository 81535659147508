/* eslint-disable no-unused-expressions */
// UI & Components
import { ContactInformationForm } from "./ContactInformationForm/ContactInformationForm";
import { BuildingAdressForm } from "./BuildingAddressForm/BuildingAddressForm";
import { toast } from "react-toastify";
import { SimpleBackdrop } from "../../components/Backdrop/Backdrop";
import { ConfirmationScreen } from "../../components/ConfirmationScreen/ConfirmationScreen";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import emailjs from "@emailjs/browser";
// Hooks
import { useState } from "react";
import { useForm } from "react-hook-form";
// API
import axios from "axios";

export const ImmocasaWebsiteForms = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const contactForm = useForm();
  const buildingForm = useForm();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const sendFileToWPApi = async (form_data, value) => {
    await axios
      .post("https://immocasa.swiss/wp-json/wp/v2/media", form_data, {
        headers: {
          Authorization:
            "Bearer 4f3fe5d6f5ad2be7d4c8a1f0f55e6b29b28cf9073f31878be8e61791c9d2e0ac",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        buildingForm.setValue(value, response.data.media_url);
      });
  };

  const sendMail = async (e) => {
    const { insurance_bat_contract, rc_bat, prime, prime_rc, sinistres } =
      buildingForm.getValues();

    const filePromises = [];

    setIsBackdropOpen(true);

    function normalizeFileName(file) {
      return file.name.replace(/[^\w.]/g, "_");
    }

    if (insurance_bat_contract) {
      const insurance_bat_contract_form = new FormData();
      insurance_bat_contract_form.append(
        "file",
        insurance_bat_contract,
        normalizeFileName(insurance_bat_contract)
      );
      filePromises.push(
        sendFileToWPApi(insurance_bat_contract_form, "insurance_bat_contract")
      );
    }

    if (rc_bat) {
      const rc_bat_form = new FormData();
      rc_bat_form.append("file", rc_bat, normalizeFileName(rc_bat));
      filePromises.push(sendFileToWPApi(rc_bat_form, "rc_bat"));
    }

    if (prime) {
      const prime_form = new FormData();
      prime_form.append("file", prime, normalizeFileName(prime));
      filePromises.push(sendFileToWPApi(prime_form, "prime"));
    }

    if (prime_rc) {
      const prime_rc_form = new FormData();
      prime_rc_form.append("file", prime_rc, normalizeFileName(prime_rc));
      filePromises.push(sendFileToWPApi(prime_rc_form, "prime_rc"));
    }

    let sinistresString = "";

    if (sinistres) {
      sinistres.forEach((sinistre, index) => {
        sinistresString += `${index + 1}. Description: ${
          sinistre.sinistre_description
        }, Montant: CHF ${sinistre.dammage_estimation}, Date: ${new Date(
          sinistre.date_sinistre
        ).toLocaleDateString()}\n`;
      });
    }

    buildingForm.setValue("sinistresString", sinistresString);

    try {
      await Promise.all(filePromises);
    } catch (error) {
      toast.error(
        "Une ou plusieurs opérations d'envoi de fichiers ont échoué:",
        error
      );
      throw error;
    }

    const fakeForm = document.createElement("form");
    fakeForm.style.display = "none";
    document.body.appendChild(fakeForm);

    const contactFormValues = contactForm.getValues();
    const buildingFormValues = buildingForm.getValues();
    const combinedFormData = { ...contactFormValues, ...buildingFormValues };

    Object.keys(combinedFormData).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = combinedFormData[key];
      fakeForm.appendChild(input);
    });

    const promiseEmailjs = emailjs
      .sendForm("service_249yuyq", "template_izjzc44", fakeForm, {
        publicKey: "f3yG7rahJ6fQsJ7Az",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );

    const res = await toast.promise(promiseEmailjs, {
      pending: "Envoi du mail en cours, merci de patienter",
      success: "L'email a bien été envoyé !",
      error:
        "Une erreur est survenue lors de l'envoi du mail, merci d'envoyer un email à info@solveo.swiss pour toute demande",
    });
    setIsBackdropOpen(false);
    setIsConfirmationOpen(true);
  };

  return (
    <div id="immocasawebsiteforms">
      <SimpleBackdrop open={isBackdropOpen} />
      <ConfirmationScreen open={isConfirmationOpen} />

      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="Tabs Example"
        variant="fullWidth"
        centered={true}
        sx={{ paddingBottom: 5 }}
      >
        <Tab label="Vos coordonnées" value={0} />
        <Tab
          label="Informations du bâtiment"
          value={1}
          disabled={!contactForm.formState.isValid}
        />
      </Tabs>
      {currentTab === 0 && (
        <ContactInformationForm form={contactForm} currentTab={setCurrentTab} />
      )}
      {currentTab === 1 && (
        <BuildingAdressForm
          form={buildingForm}
          currentTab={setCurrentTab}
          sendMail={sendMail}
        />
      )}
    </div>
  );
};
