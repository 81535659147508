import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

export const PhoneInputControlled = ({
  rules,
  control,
  name,
  favorite_country,
  default_country,
  label,
  errors,
}) => {
  if (!rules) rules = {};
  rules["validate"] = (value) =>
    matchIsValidTel(value) || value === ""
      ? true
      : "Le format n'est pas correcte - Exemple: +41 22 332 29 82";

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={rules}
      render={({ field }) => (
        <MuiTelInput
          {...field}
          preferredCountries={favorite_country}
          defaultCountry={default_country}
          fullWidth
          label={label}
          error={Boolean(errors)}
          helperText={errors?.message}
        />
      )}
    />
  );
};
