import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ImmocasaWebsiteForms } from './apps/ImmocasaWebsiteForms/ImmocasaWebsiteForms';
import { BlankApp } from './apps/BlankApp/BlankApp';

const AppRoutes = () => {
 return (
    <Router>
       <Routes>
          <Route path="/" element={<BlankApp />} />
          <Route path="/apps/immocasa/websiteforms" element={<ImmocasaWebsiteForms />} />
       </Routes>
    </Router>
 );
};

export default AppRoutes;
