// UI & Components
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";

function EditToolbar(props) {
  const handleSaveOrEdit = () => {
    toast.success("Les sinistres ont été sauvegardés avec succès.");
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        p: 1,
      }}
    >
      <Button onClick={handleSaveOrEdit} disabled={false} variant="outlined">
        Enregistrer les sinistres
      </Button>
    </Box>
  );
}

export const CustomDataGrid = ({ rows, onEditRow }) => {
  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        editMode="row"
        rows={rows}
        columns={columns}
        hideFooter={true}
        processRowUpdate={(updatedRow, originalRow) => {
          if (updatedRow && updatedRow.id) {
            onEditRow(updatedRow);
          } else {
            console.error(
              "Erreur lors de la mise à jour de la ligne : 'updatedRow' n'est pas défini ou ne contient pas de propriété 'id'"
            );
          }
        }}
        onProcessRowUpdateError={(error) => {
          console.error(error);
        }}
      />
    </div>
  );
};

const columns = [
  {
    field: "sinistre_description",
    headerName: "Description du sinistre",
    minWidth: 500,
    editable: true,
    sortable: false,
  },
  {
    field: "dammage_estimation",
    headerName: "Montant du sinistre",
    type: "number",
    editable: true,
    align: "left",
    headerAlign: "left",
    width: 250,
    sortable: false,
  },
  {
    field: "date_sinistre",
    headerName: "Date du sinistre",
    type: "date",
    width: 180,
    editable: true,
    sortable: false,
  },
];
