import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const ConfirmationScreen = ({ open }) => {
  return (
    <div>
      <Backdrop
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.8)", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <CheckCircleIcon sx={{ fontSize: 80, animation: "pulse 1s infinite" }} />
          <br />
          <Typography variant="h5">Le formulaire a été envoyé avec succès!</Typography>
        </div>
      </Backdrop>
    </div>
  );
};
