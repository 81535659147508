// UI & Components
import { TextFieldControlled } from "../../../components/TextFieldControlled/TextFieldControlled";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import { RadioButtonControlled } from "../../../components/RadioButtonControlled/RadioButtonControlled";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// Hooks
import { useState, useEffect } from "react";
// Moment
import moment from "moment";

const optionsBool = [
  { id: 1, key: "yes", label: "Oui" },
  { id: 2, key: "no", label: "Non" },
];

export const BuildingAdressForm = ({ form, currentTab, sendMail }) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = form;

  const [currentSinistres, setCurrentSinistres] = useState([]);

  const handleValidateStep = () => {
    sendMail();
  };

  const handleFormError = () => {};

  const handleCreateRowsSinistres = () => {
    const nb_sinistres = watch("nb_sinistres");

    const sinistresArray = [];

    for (let i = 0; i < nb_sinistres; i++) {
      sinistresArray.push({
        id: i + 1,
        sinistre_description: "",
        dammage_estimation: "",
        date_sinistre: new Date(),
      });
    }

    setCurrentSinistres(sinistresArray);
  };

  useEffect(() => {
    handleCreateRowsSinistres();
  }, [watch("nb_sinistres")]);

  const handleEditRow = (data) => {
    const updatedSinistres = currentSinistres.map((currentSinistre) => {
      if (currentSinistre.id === data.id) {
        return { ...currentSinistre, ...data };
      } else {
        return currentSinistre;
      }
    });
    setCurrentSinistres(updatedSinistres);
    setValue("sinistres", updatedSinistres);
  };

  const handleSelectFileInsuranceBatContract = async (base64file) => {
    // contrat d’assurance bâtiment
    setValue("insurance_bat_contract", base64file);
  };
  const handleSelectFileRCBat = async (base64file) => {
    // contrat d’assurance Responsabilité Civile Immeuble
    setValue("rc_bat", base64file);
  };
  const handleSelectFilePrime = async (base64file) => {
    // avis de prime bâtiment
    setValue("prime", base64file);
  };
  const handleSelectFilePrimeRC = async (base64file) => {
    // avis de prime RC Immeuble
    setValue("prime_rc", base64file);
  };

  return (
    <form onSubmit={handleSubmit(handleValidateStep, handleFormError)}>
      <Grid container spacing={3}>
        <Grid container item alignItems="flex-start">
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <TextFieldControlled
                control={control}
                name="coproperty_name"
                label="Nom du propriétaire ou de la copropriété "
                type="text"
                errors={errors.coproperty_name}
                rules={{
                  required:
                    "Le nom de la copropriété / propriétaire est obligatoire",
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextFieldControlled
                control={control}
                name="bat_adress"
                label="Adresse"
                type="text"
                errors={errors.bat_adress}
                rules={{ required: "L'adresse est obligatoire" }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldControlled
                control={control}
                name="bat_npa"
                label="Code Postal"
                type="text"
                errors={errors.bat_npa}
                rules={{ required: "Le code postal est obligatoire" }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControlled
                control={control}
                name="bat_city"
                label="Ville"
                type="text"
                errors={errors.bat_city}
                rules={{ required: "La ville est obligatoire" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControlled
                control={control}
                name="sum_insurance"
                label="Somme d'assurance actuelle"
                type="number"
                adornment="CHF"
                errors={errors.sum_insurance}
                rules={{ required: "La somme d'assurance est obligatoire" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControlled
                control={control}
                name="nb_sinistres"
                label="Nombre de sinistres ces 5 dernières années"
                helperText="Si vous n'avez pas cette information, nous pourrons nous en charger pour vous."
                type="number"
                errors={errors.nb_sinistres}
              />
            </Grid>

            <Grid item xs={6}>
              <RadioButtonControlled
                control={control}
                name="swimming_pool"
                label="Avez-vous une piscine ?"
                options={optionsBool}
                errors={errors.swimming_pool}
                rules={{ required: "Le champ 'piscine' est obligatoire" }}
              />
            </Grid>
            <Grid item xs={6}>
              <RadioButtonControlled
                control={control}
                name="solar_panel"
                label="Avez-vous des panneaux photovoltaïques ?"
                options={optionsBool}
                errors={errors.solar_panel}
                rules={{ required: "Le champ 'Panneaux photovoltaïques' est obligatoire" }}
              />
            </Grid>
            <Grid item xs={6}>
              {watch("swimming_pool") === "Oui" ? (
                <TextFieldControlled
                  control={control}
                  name="value_swimming_pool"
                  label="Valeur de la piscine"
                  type="number"
                  errors={errors.value_swimming_pool}
                  adornment="CHF"
                />
              ) : null}
            </Grid>
            <Grid item xs={6}>
              {watch("solar_panel") === "Oui" ? (
                <TextFieldControlled
                  control={control}
                  name="value_solar_panel"
                  label="Valeur des panneaux photovoltaïques"
                  type="number"
                  errors={errors.value_solar_panel}
                  adornment="CHF"
                />
              ) : null}
            </Grid>
            {watch("nb_sinistres") >= 1 ? (
              <Grid item xs={12}>
                <CustomDataGrid
                  rows={currentSinistres}
                  onEditRow={handleEditRow}
                />
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <FileUpload
                label="Téléchargez votre contrat d’assurance bâtiment"
                onSelectFile={handleSelectFileInsuranceBatContract}
                allowedExt={["jpg", "png", "pdf"]}
              />
            </Grid>
            <Grid item xs={6}>
              <FileUpload
                label="Téléchargez votre contrat d’assurance Responsabilité Civile Immeuble"
                onSelectFile={handleSelectFileRCBat}
                allowedExt={["jpg", "png", "pdf"]}
              />
            </Grid>
            <Grid item xs={6}>
              <FileUpload
                label="Téléchargez votre dernier avis de prime bâtiment"
                onSelectFile={handleSelectFilePrime}
                allowedExt={["jpg", "png", "pdf"]}
              />
            </Grid>
            <Grid item xs={6}>
              <FileUpload
                label="Téléchargez votre dernier avis de prime RC Immeuble"
                onSelectFile={handleSelectFilePrimeRC}
                allowedExt={["jpg", "png", "pdf"]}
              />
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={3}>
              <Grid>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={!isValid}
                >
                  Envoyer le formulaire
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
