// UI & Components
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// Utils
import { Controller } from "react-hook-form";

export const TextFieldControlled = ({
  control,
  name,
  defaultValue,
  rules,
  disabled,
  sx,
  label,
  errors,
  helperText,
  multiline,
  type,
  size,
  maxLength,
  adornment,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          disabled={disabled}
          fullWidth
          sx={sx}
          variant="outlined"
          label={label}
          error={Boolean(errors)}
          helperText={errors ? errors?.message : helperText}
          multiline={multiline}
          type={type}
          rows={4}
          size={size}
          inputProps={{ maxLength: maxLength }}
          InputProps={ adornment ? {
            startAdornment: (
              <InputAdornment position="start">{adornment}</InputAdornment>
            ),
          } : null}
        />
      )}
    />
  );
};

/*
Example:
<TextFieldControlled control={control} name="first_name" label="Prénom" 
    type="text" errors={errors.first_name} rules={{ required: "Le prénom est obligatoire", }} />
*/
