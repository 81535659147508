// UI & Components
import { ImmocasaWebsiteForms } from "./apps/ImmocasaWebsiteForms/ImmocasaWebsiteForms";
import { SolveoWebsiteForms } from "./apps/SolveoWebsiteForms/SolveoWebsiteForms";
import { BlankApp } from "./apps/BlankApp/BlankApp";

function ComponentSelector() {
  const rootElement = document.getElementById("solveoapps");
  const appName = rootElement.getAttribute("data-app");

  switch (appName) {
    case "immocasawebsiteforms":
      return <ImmocasaWebsiteForms />;
    case "solveowebsiteforms":
      return <SolveoWebsiteForms />;
    case "blank":
      return <BlankApp />;
    default:
      return null;
  }
}

export default ComponentSelector;
