// UI & Components
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { toast } from 'react-toastify';
// Helpers
// import { convert_file_to_base_64 } from "../../utils/helper";
// Hooks
import { useEffect, useState } from "react";

export const FileUpload = (props) => {
  const [file, setFile] = useState(null);
  const [deletePicture, setDeletePicture] = useState(false);
  const [isAllowedPdf, setIsAllowedPdf] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [fileName, setFileName] = useState(null);
  const imgTypes = ["png", "jpg", "jpeg"];

  const handleChange = async (event) => {
    onChangeFile(event.target.files[0]);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    onChangeFile(e.dataTransfer.files[0]);
  };

  const onChangeFile = async (file) => {
    if (file) {
      if (validateFileExt(file.name)) {
        setFile(URL.createObjectURL(file));
        // const base64file = await convert_file_to_base_64(file);
        props.onSelectFile(file);
        setDeletePicture(false);
        setFileExtension(getFileExt(file.name));
        
        setFileName(file.name);
      } else {
        toast.error("Erreur - Extensions autorisées: " + props.allowedExt);
      }
    } else {
      console.error("Pas de fichier");
    }
  };

  const validateFileExt = (filename) => {
    const ext = getFileExt(filename);

    if (props.allowedExt.includes(ext)) {
      return true;
    } else {
      return false;
    }
  };

  const getFileExt = (filename) => {
    if (filename != null) {
      const ext = filename.substring(filename.lastIndexOf(".") + 1);
      return ext;
    }
  };

  const handleDeletePicture = () => {
    setFile(null);
    props.onSelectFile(null);
    setDeletePicture(true);
  };

  const checkIfPropsAllowedComponent = () => {
    if (props.allowedExt.find((element) => element === "pdf") != undefined) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsAllowedPdf(checkIfPropsAllowedComponent);
    setFileExtension(getFileExt(props.fileUrl));
  }, []);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => e.preventDefault()}
      >
        <Box
          component="span"
          sx={{ p: 2, border: "1px dashed grey", m: "auto" }}
          textAlign="center"
        >
          <Button variant="contained" component="label">
            <PhotoCamera />
            &nbsp;{props.label}
            {isAllowedPdf ? (
              <input
                hidden
                accept="image/*,.pdf"
                multiple
                type="file"
                onChange={handleChange}
              />
            ) : (
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleChange}
              />
            )}
          </Button>

          {file ? (
            <div style={{ marginTop: "20px" }}>
              {imgTypes.includes(fileExtension) && (
                <img src={file} width="210px" alt="im to upload 01" />
              )}
              {fileExtension === "pdf" && (
                <a href={file} target="_blank" rel="noreferrer">
                  {fileName}
                </a>
              )}
            </div>
          ) : (
            props.fileUrl &&
            !deletePicture && (
              <div style={{ marginTop: "20px" }}>
                {imgTypes.includes(fileExtension) && (
                  <img
                    src={props.fileUrl}
                    width="210px"
                    alt="im to upload 02"
                  />
                )}
                {fileExtension === "pdf" && (
                  <a href={props.fileUrl} target="_blank" rel="noreferrer">
                    {fileName}
                  </a>
                )}
              </div>
            )
          )}

          {(file || props.fileUrl) && !deletePicture ? (
            <Button
              style={{ marginTop: "20px" }}
              centered="true"
              onClick={() => handleDeletePicture()}
              color="error"
              startIcon={<DeleteIcon />}
            >
              Supprimer la pièce jointe
            </Button>
          ) : null}
        </Box>
      </Stack>
    </>
  );
};

/*
Example:
<FileUpload label="Uploader une image" onSelectFile={handleSelectFile} 
    fileUrl={getValues("image")} allowedExt={['jpg', 'png']} />
*/
